import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  animations: [
    trigger('menuResize', [
      state('small', style({width: '17vh', height: '17vh'})),
      state('medium', style({width: '27vh', height: '27vh'})),
      transition('small <=> medium', [
        animate(200)
      ])
    ]),

    trigger('burgerDisplay', [
      state('burgerShow', style( {top: '0'})),
      state('burgerHide', style({top: '-75vh'})),
      transition('burgerShow <=> burgerHide', [
        animate(200)
      ])
    ]),
    trigger('lightSwitch', [
      state('lightOn', style( {'background-color': 'rgb(100,100,100)'})),
      state('lightOff', style( {'background-color': 'rgb(0,0,0)'})),
      transition('lightOn<=> lightOff', [
        animate(200)
      ])
    ]),
  ]
})
export class MenuComponent implements OnInit {
  resize = 'medium';
  burgerMenuState = 'burgerHide';
  lightState = 'lightOff';
  constructor() {
  }

  ngOnInit() {}

  burgerOnClick() {

    if (this.burgerMenuState === 'burgerHide') {
      this.burgerMenuState = 'burgerShow';
      this.lightState = 'lightOn';
    } else {
      this.burgerMenuState = 'burgerHide';
      this.lightState = 'lightOff';
    }
  }
  navHome() {
      this.resize = 'medium';

  }
  resizeSmall() {
    this.resize = 'small';
  }
  resizeMedium() {
    this.resize = 'medium';
  }

}
