import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { GetDatas } from '../../services/getDatas.service';

import * as jQuery from 'jquery';
declare var jQuery: any;
@Component({
  selector: 'app-gal',
  templateUrl: './gal.component.html',
  styleUrls: ['./gal.component.css'],
  animations: [
    trigger('lineDisplay', [
      state('void', style({opacity: 0})),
      transition('void <=> *', [
        animate('500ms', style({opacity: 1}))
      ])
    ])
  ]
})
export class GalComponent implements OnInit, AfterViewInit {
  @Input() gallerie;
  
  imgList = [];
  constructor(private getphotos : GetDatas) {

  }
  deviceCheck() {
    if (window.innerWidth > 900) {
      return 'laptop';
    } else {
      return 'mobile';
    }
  }
  ngOnInit() {
    this.getphotos.getDatasFromApi(this.gallerie['url'])
    .subscribe((datas)=>{
      datas['body'][0]['value'] .forEach(img => {
        let thisImg = {
          'isActive': false,
          'url': this.getphotos.baseUrl + img['image_url'],
        } 
        this.imgList.push(thisImg)
      });
    })
  }

  onImgClick(i){
    this.imgList[i]['isActive'] = !this.imgList[i]['isActive']
  }

  onArrowClick(i, change) {
    this.imgList[i]['isActive'] = false;
    if (change === 'left' && i > 0){
      this.imgList[i - 1]['isActive'] = true;
    } else if (change === 'right'&& i < this.imgList.length - 1) {
      this.imgList[i + 1]['isActive'] = true;
    }

  }
  ngAfterViewInit() {
  }

}
