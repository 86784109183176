import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, state, style, animate } from '@angular/animations';

@Component({
  selector: 'app-projet-min-detail',
  templateUrl: './projet-min-detail.component.html',
  styleUrls: ['./projet-min-detail.component.css'],
  animations: [
    trigger('Show', [
      state('void', style({transform : 'translateY(90%)'})),
      transition('void <=> *', animate(250))
    ])
  ]
})
export class ProjetMinDetailComponent implements OnInit {
  @Input() textDescription: string;
  constructor() { }

  ngOnInit() {
  }

}
