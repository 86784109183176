import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactUs } from 'src/app/services/contactUs.service';
@Component({
  selector: 'app-form-contact',
  templateUrl: './form-contact.component.html',
  styleUrls: ['./form-contact.component.css']
})
export class FormContactComponent implements OnInit {
  mailValidation = false;
  constructor(private contactUs: ContactUs, private router: Router, private route: ActivatedRoute) { 
  }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {
    const msg = {'email': form.value['email'], 'objet': form.value['objet'], corp: form.value['corp']}
    this.contactUs.sendMailToServer(msg).subscribe((data)=>{
      this.mailValidation = true;
    }, (err: HttpErrorResponse) => {
      console.log(err)
    }
    )
  }

  onValidationClick() {
    this.mailValidation = false;
    this.router.navigate([''], {relativeTo: this.route})
  }
}
