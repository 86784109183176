import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-text-part',
  templateUrl: './text-part.component.html',
  styleUrls: ['./text-part.component.css']
})
export class TextPartComponent implements OnInit {
  @Input() datas;
  @Input() index;
  constructor() { }

  ngOnInit() {
  }

}
