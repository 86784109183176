import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-video-part',
  templateUrl: './video-part.component.html',
  styleUrls: ['./video-part.component.css']
})
export class VideoPartComponent implements OnInit {
  @Input() datas;
  videosource: string;
  constructor() {

   }
  
  ngOnInit() {
    this.datas.id = this.datas.value.split('v=')[1];
    this.videosource = 'https://www.youtube.com/embed/' + this.datas.id ;
  }

}
