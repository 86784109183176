import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';
import { GetDatas } from 'src/app/services/getDatas.service';

@Component({
  selector: 'app-gal-tab',
  templateUrl: './gal-tab.component.html',
  styleUrls: ['./gal-tab.component.css']
})

export class GalTabComponent implements OnInit {
  @Input() index;
  @Input() name;
  @Input() thumbnailUrl;
  @Output() hideTabs = new EventEmitter();

  onMouseClick() {
    this.hideTabs.emit(this.index);
  }
  ngOnInit() {
  }



}
