import { Component, OnInit } from '@angular/core';
import {trigger, state, style, animate, transition } from '@angular/animations';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('showHide', [

      state('show', style({
        opacity: 1,
      })),
      state('hide', style({
        opacity: 0,
      })),
      transition('show <=> hide', [
        animate('1s')
      ]),
    ]),
    trigger('mentionDisplay', [
      state('false', style({transform: 'translateY(15vw)'})),
      state('true', style({transform: 'translatey(0)'})),
      transition('false <=> true', [
        animate('200ms ease')
      ])
    ])
  ],
})
export class HomeComponent implements OnInit {
background = [true, false, false];
mentionDisplay = false;
count = 0;

constructor() {
}
onMentionClick() {
  this.mentionDisplay = !this.mentionDisplay;
}
ngOnInit() {
    setInterval(
      () => {
        this.background[this.count] = !this.background[this.count];
        if (this.count === 2) {
          this.count = 0;
        } else {this.count ++; }
        this.background[this.count] = !this.background[this.count];
      }, 4000
    );
  }

}
