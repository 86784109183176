import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { state, trigger, style, transition, animate } from '@angular/animations';
import { GetDatas } from '../services/getDatas.service';
@Component({
  selector: 'app-galeries',
  templateUrl: './galeries.component.html',
  styleUrls: ['./galeries.component.css'],
  animations: [
    trigger('tabsDisplayLaptop', [
      state('void', style({transform: 'translateX(-40vw)'})),
      transition('void <=> *', [
        animate('300ms ease')
      ])
    ]),
    trigger('tabsDisplayMobile', [
      state('void', style({transform: 'translateX(-100w)'})),
      transition('void <=> *', [
        animate('300ms ease')
      ])
    ]),
    trigger('arrowAnimationLaptop', [
      state('false', style({transform: 'translateX(-40vw)'})),
      state('true', style({transform: 'translateX(0)'})),
      transition('false <=> true', [
        animate('300ms ease')
      ])
    ]),
    trigger('arrowAnimationMobile', [
      state('false', style({transform: 'translateX(-100vw)'})),
      state('true', style({transform: 'translateX(0)'})),
      transition('false <=> true', [
        animate('300ms ease')
      ])
    ]),
    trigger('arrowReverse', [
      state('false', style({transform: 'rotate(0deg) translateX(0vw)'})),
      state('true', style({transform: 'rotate(-180deg) translateX(40vw)'})),
      transition('false <=> true',[
        animate('300ms ease')
      ])
    ])
  ]

})
export class GaleriesComponent implements OnInit, AfterViewInit, OnDestroy {
  photosList = [];
  currentGal = 0;
  tabsDisplayLaptop: boolean;
  tabsDisplayMobile: boolean;
  tabsDisplay = true;
  deviceCheck() {
    if (window.innerWidth > 900) {
      return 'laptop';
    } else {
      return 'mobile';
    }
  }
  toHideTabs(position: number) {
    const previousPosition = this.currentGal
    this.currentGal = position;

    if(previousPosition !== 0){
      this.photosList[previousPosition -1]['isActive'] = false;
    }
    this.photosList[position - 1]['isActive'] = true;
    this.tabsDisplay = false;
    this.tabsDisplayLaptop = false;
    this.tabsDisplayMobile = false;
  }
  onArrowClick() {
    this.tabsDisplay = ! this.tabsDisplay;
    if (this.deviceCheck() === 'laptop') {
      this.tabsDisplayLaptop = !this.tabsDisplayLaptop;
  } else if (this.deviceCheck() === 'mobile') {
      this.tabsDisplayMobile = !this.tabsDisplayLaptop;
    }
  }


  constructor(private getPhotosList: GetDatas) {
    const galleries = []
    this.getPhotosList.getDatasFromApi( this.getPhotosList.baseUrl +
      '/back-end/api/v2/pages/?type=home.GalleriePage&fields=thumbnail')
      .subscribe(data => {
      data['items'].forEach(gal => {
        const gallerie = {
          'isActive': false,
          'titre': gal['title'],
          'thumbnail_url': gal['thumbnail']['meta']['download_url'],
          'url': gal['meta']['detail_url']
        }

        galleries.push(gallerie)
      });
    })

    this.photosList = galleries

    if (this.deviceCheck() === 'laptop') {
      this.tabsDisplayLaptop = true;
    } else if (this.deviceCheck() === 'mobile') {
      this.tabsDisplayMobile = true;
    }

  }

  ngOnInit() {
    
  }


  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.photosList.forEach(list => {
      list.isActive = false;
    })
  }
}
