import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-photobloc-part',
  templateUrl: './photobloc-part.component.html',
  styleUrls: ['./photobloc-part.component.css']
})
export class PhotoblocPartComponent implements OnInit {
  @Input() datas;
  images = [];

  constructor() { }
  onSwitch(index) {
    this.images[index]['isActive'] = !this.images[index]['isActive']
  }

  onArrowClick(i, change) {
    this.images[i]['isActive'] = false;
    if (change === 'left' && i > 0){
      this.images[i - 1]['isActive'] = true;
    } else if (change === 'right'&& i < this.images.length - 1) {
      this.images[i + 1]['isActive'] = true;
    }

  }

  ngOnInit() {
    this.datas.images.forEach(img => {
      const imgSwitch = {'img': img, 'isActive': false}
      this.images.push(imgSwitch);
    });
  }

}
