import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tarifs',
  templateUrl: './tarifs.component.html',
  styleUrls: ['./tarifs.component.css']
})
export class TarifsComponent implements OnInit {
  packShootingElts = [
    // tslint:disable: max-line-length
    {'type': 'Forfait réseaux sociaux', 'content': 'petite séance + séléction de 5photos', 'prices':[80, 100], 'image':'../../assets/img/forfaits/photo1.jpg'},
    {'type': 'Forfait mini book', 'content': 'Petite séance + sélection de 10 photos', 'prices':[120, 140], 'image':'../../assets/img/forfaits/photo2.jpg'},
    {'type': 'Forfait book light', 'content': 'Séance moyenne + sélection de 20 photos', 'prices':[200, 220], 'image':'../../assets/img/forfaits/photo3.jpg'},
    {'type': 'Forfait book', 'content': 'Demi journée de shooting + séléction de 40 photos', 'prices':[350, 370], 'image':'/../../assets/img/forfaits/photo4.jpg'}
  ];

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

  }
  onContactClick() {
    this.router.navigate(['contact'])
  }
}
