import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate, animateChild, group, query } from '@angular/animations';

@Component({
  selector: 'app-projet-min',
  templateUrl: './projet-min.component.html',
  styleUrls: ['./projet-min.component.css'],
  animations: [
    trigger('hoverProjet', [
      state('hoverOn', style({
        transform: 'translateY(-100%)',
        'background-color': 'rgba(50, 50, 50, 0.7)'})),
      transition('hoverOn <=> hoverOff', [animate(250)]),
      ]),
    ]
})
export class ProjetMinComponent implements OnInit {
  @Input() projet;
  @Input() index: number;
  img: string;
  title: string;
  icone: string;
  cat: string;
  hover: boolean;
  constructor() {

   }
  mouseEnter() {
    this.hover = true;
  }

  mouseLeave() {
    this.hover = false;
  }
  fullSwitch() {
    this.projet['switch'] = !this.projet['switch']
  }
  ngOnInit() {
    this.img = this.projet.min.img;
    this.title = this.projet.titre;
    this.icone = this.projet.min.icone;
    this.cat = this.projet.categorie;

  }

}
