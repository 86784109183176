import { Component, Input } from '@angular/core';
import { query, trigger, transition, style, animate, group, animateChild } from '@angular/animations';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('routeAnimation', [
        transition('1 => 2', [
            style({ height: '!' }),
            query(':enter', style({ transform: 'translateY(100%)' })),
            query(':enter, :leave', style({ position: 'fixed', top: 0, left: 0, right: 0 })),
            group([
                query(':leave', [
                    animate('0.3s cubic-bezier(.35,0,.25,1)', style({ transform: 'translateY(-100%)' })),
                ]),
                query(':leave', animateChild()),
                group([
                  query(':leave', [
                    animate('300ms ease-out', style({ opacity: 0}))
                  ]),
                  query(':enter', [
                    animate('300ms ease-out', style({ opacity: 1}))
                  ])
                ]),
                query(':enter', animate('0.3s cubic-bezier(.35,0,.25,1)', style({ transform: 'translate(0)' }))),
            ]),

        ]),
        transition('2 => 1', [
            style({ height: '!' }),
            query(':enter', style({ transform: 'translateY(-100%)' })),
            query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 })),
            group([
                query(':leave', [
                    animate('0.3s cubic-bezier(.35,0,.25,1)', style({ transform: 'translateY(100%)' })),
                ]),
                query(':enter', animateChild()),
                group([
                  query(':enter', [
                    animate('30ms ease-out', style({opacity: 0}))
                  ]),
                  query(':leave', [
                    animate('30ms ease', style({ opacity: 1}))
                  ])
                ]),
                query(':enter', animate('0.3s cubic-bezier(.35,0,.25,1)', style({ transform: 'translateY(0)' }))),
            ]),
        ]),
    ])
]
})
export class AppComponent {
  title = 'KDphotographie';
  menuState = 'true';

  constructor() {
  }

  getState(outlet) {
    return outlet.activatedRouteData['state']
  }
}
