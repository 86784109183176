import { Component, OnInit, AfterViewInit} from '@angular/core';
import { GetDatas } from '../services/getDatas.service';
import * as jQuery from 'jquery';
declare var jQuery: any;

@Component({
  selector: 'app-projets',
  templateUrl: './projets.component.html',
  styleUrls: ['./projets.component.css']
})
export class ProjetsComponent implements OnInit, AfterViewInit {
  projets = [];

  constructor(private getProjets: GetDatas) {
    this.getProjets.getDatasFromApi(
      this.getProjets.baseUrl + 
      '/back-end/api/v2/pages?type=home.ProjetPage&fields=categorie,minIcon,minImg,description').subscribe(data => {
      data['items'].forEach(rawProject => {
        const project = this.convertProject(rawProject)
        this.projets.push(project)
      });
    })

   }

  convertProject(rawProject) {
    const minProject = {min:{}}
    minProject['switch'] = false
    minProject['s_id'] = rawProject['id']
    minProject['titre'] = rawProject['title']
    minProject['categorie'] = rawProject['categorie']
    minProject['min']['icone'] = rawProject['minIcon']
    minProject['min']['img'] = this.getProjets.baseUrl + rawProject['minImg']['meta']['download_url']
    minProject['min']['description'] = rawProject['description']
    return minProject
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
  }


}
