import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';


@Injectable()
export class GetDatas {

    baseUrl = 'https://kdphotographie.fr'

    constructor(private http: HttpClient) { }

    getDatasFromApi(url: string) {
        const headers = new HttpHeaders().set(
            'Content-Type',  'application/json'
          );
        const option = {headers: headers};
        return this.http.get(url, option)
    }

}

