import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './menu/menu.component';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { GaleriesComponent } from './galeries/galeries.component';
import { Routes, RouterModule } from '@angular/router';
import { GalTabComponent } from './galeries/gal-tab/gal-tab.component';
import {GetDatas} from './services/getDatas.service';
import {ContactUs} from './services/contactUs.service';
import { ProjetsComponent } from './projets/projets.component';
import { ProjetMinComponent } from './projets/projet-min/projet-min.component';
import { ProjetMinDetailComponent } from './projets/projet-min/projet-min-detail/projet-min-detail.component';
import { ProjetFullComponent } from './projets/projet-full/projet-full.component';
import { VideoPartComponent } from './projets/projet-full/video-part/video-part.component';
import { TextPartComponent } from './projets/projet-full/text-part/text-part.component';
import { PhotoblocPartComponent } from './projets/projet-full/photobloc-part/photobloc-part.component';
import { SafeUrlPipe } from './projets/projet-full/video-part/safe-url.pipe';
import { ContactComponent } from './contact/contact.component';
import { TarifsComponent } from './tarifs/tarifs.component';
import { PackShootingEltComponent } from './tarifs/pack-shooting-elt/pack-shooting-elt.component';
import { FormContactComponent } from './contact/form-contact/form-contact.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { GalComponent } from './galeries/gal/gal.component';
const appRoutes: Routes = [
  { path: 'home', component: HomeComponent, data: {state: 1} },
  { path: 'galeries', component: GaleriesComponent, data: {state: 1}},
  { path: 'projets', component: ProjetsComponent, data: {state: 1}},
  { path: '', component: HomeComponent, data: {state: 1} },
  { path: 'tarifs', component: TarifsComponent, data: {state: 1}},
  { path: 'contact', component: ContactComponent, data: {state: 1}}
];
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    GaleriesComponent,
    GalTabComponent,
    ProjetsComponent,
    ProjetMinComponent,
    ProjetMinDetailComponent,
    ProjetFullComponent,
    VideoPartComponent,
    TextPartComponent,
    PhotoblocPartComponent,
    SafeUrlPipe,
    ContactComponent,
    TarifsComponent,
    PackShootingEltComponent,
    FormContactComponent,
    GalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),

  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},

    GetDatas,
    ContactUs,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
