import { Component, OnInit, Input } from '@angular/core';
import { GetDatas } from '../../services/getDatas.service';

@Component({
  selector: 'app-projet-full',
  templateUrl: './projet-full.component.html',
  styleUrls: ['./projet-full.component.css']
})
export class ProjetFullComponent implements OnInit {
  @Input() projets: any[];
  @Input() index: any;
  projet: any;
  projetFull = [];
  title: any;
  constructor(private getprojets: GetDatas) {

  }

  convertTextPart(rawPart) {
    const cleanPart = {}
    cleanPart['type'] = 'text'
    cleanPart['text'] = rawPart['value']['blocText']
    cleanPart['blocTitle'] = rawPart['value']['blocTitle']
    return cleanPart
  }

  convertImgPart(rawPart) {
    const cleanPart = {}
    cleanPart['type'] = 'photobloc'
    cleanPart['images'] = []
    cleanPart['blocTitle'] = rawPart['value'].shift()
    rawPart['value'].forEach(img => {
      cleanPart['images'].push(this.getprojets.baseUrl + img['image_url'])
    });

    return cleanPart
  }

  convertVideoPart(rawPart) {
    const cleanPart = {}
    cleanPart['type'] = 'video'
    cleanPart['value'] = rawPart['value']
    return cleanPart
  }

  switchOff() {
    this.projet['switch'] = !this.projet['switch']
  }
  ngOnInit() {
    this.projet = this.projets[this.index];
    this.title = this.projet.titre;
    this.getprojets.getDatasFromApi(this.getprojets.baseUrl + '/back-end/api/v2/pages/' + this.projet['s_id']).subscribe((data)=>{
      data['body'].forEach(rawPart => {
        if (rawPart['type'] === 'text') {
          this.projetFull.push(this.convertTextPart(rawPart))
        } else if (rawPart['type'] === 'titledimagebloc') {
          this.projetFull.push(this.convertImgPart(rawPart))
        } else if (rawPart['type'] === 'video') {
          this.projetFull.push(this.convertVideoPart(rawPart))
        }
      });
    })
  }

}
