import { Component, OnInit, Input } from '@angular/core';
import {trigger, state, style, animate, transition } from '@angular/animations';
@Component({
  selector: 'app-pack-shooting-elt',
  templateUrl: './pack-shooting-elt.component.html',
  styleUrls: ['./pack-shooting-elt.component.css'],
})
export class PackShootingEltComponent implements OnInit {
  @Input() index;
  @Input() elt;
  imgIndex = 0;

  constructor() { 
  }

  ngOnInit() {
  }

}
