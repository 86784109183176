import { HttpClient, HttpErrorResponse, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable()
export class ContactUs {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    constructor(private httpClient: HttpClient){}
    sendMailToServer(form) {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        let body = `email=${form['email']}&objet=${form['objet']}&corp=${form['corp']}`
        const request =  this.httpClient.post('https://kdphotographie.fr/ask', body.toString(), options)
        return request
    }
}
